import {mapGetters, mapMutations} from "vuex";

export default{
  computed:{
    ...mapGetters({settings: 'settings/settings'})
  },
  methods:{
    ...mapMutations({
      setSettings: 'settings/settings/SET_SETTINGS',
    }),
    getUrlParams(){
      if(this.$route.query){
        for (let param in this.$route.query) {
          if(
            param === 'utm_source'
            || param === 'utm_medium'
            || param === 'utm_term'
            || param === 'utm_content'
            || param === 'utm_campaign'){
            if(this.$route.query[param]){
              localStorage.setItem(param, this.$route.query[param])
            }
          }
        }
      }
    }
  },
  async mounted(){
    await this.getUrlParams();
    let rules = null;
    if (Object.values(this.$route.query).indexOf('auto-otzovik') > -1) {
      rules = {
        utm_source: 'auto-otzovik',
        utm_medium: 'organic',
        utm_campaign: 'auto-otzovik',
        utm_content: 'sear4-otz',
      }
    } else if (Object.values(this.$route.query).indexOf('top-otzivov') > -1) {
      rules = {
        utm_source: 'top-otzivov',
        utm_medium: 'organic',
        utm_campaign: 'top-otzivov',
        utm_content: 'sear4-otz',
      }
    } else if (Object.values(this.$route.query).indexOf('autoreview-help') > -1) {
      rules = {
        utm_source: 'autoreview-help',
        utm_medium: 'organic',
        utm_campaign: 'autoreview-help',
        utm_content: 'sear4-otz',
      }
    } else if (Object.values(this.$route.query).indexOf('auto-review') > -1) {
      rules = {
        utm_source: 'auto-review',
        utm_medium: 'organic',
        utm_campaign: 'auto-review',
        utm_content: 'sear4-otz',
      }
    }
    if(rules !== null) {
      const isOtzovik = Object.entries(rules).every(([key, value]) => {
        return localStorage.getItem(key) === value;
      }) && this.settings.phone_top_otzovik;
      if (isOtzovik) this.setSettings({...this.settings, phone: this.settings.phone_top_otzovik})
    }
  }
}
