
import {mapActions, mapGetters} from "vuex";

export default {
	computed: {
		...mapGetters({
			benefit: 'benefits/benefit'
		}),
		benefitText(){
			return this.benefit.text + (this.benefit.text_strong?this.benefit.text_strong:'')
		}
	},
	methods: {
		...mapActions({
			closeBenefit: 'benefits/closeBenefit'
		})
	}
}
