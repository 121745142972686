
import {mapActions, mapGetters} from "vuex";

export default {
	computed: {
		...mapGetters({
			benefitModal: 'benefits/benefitModal'
		})
	},
	methods: {
		...mapActions({
			closeBenefit: 'benefits/closeBenefit'
		})
	}
}
