
export default {
  data() {
    return {
      stateModalCookie: false
    }
  },
  methods: {
    changeStateModalCookie() {
      document.cookie = "stateModal=1; max-age=259200";
      this.stateModalCookie = !this.stateModalCookie
    }
  },
  beforeMount() {
    if (!document.cookie.match(/stateModal=(.+?)(;|$)/)) {
      this.stateModalCookie = true
    }
  },
}
