import { render, staticRenderFns } from "./modal-cookie-main.vue?vue&type=template&id=a005ff9e&scoped=true&"
import script from "./modal-cookie-main.vue?vue&type=script&lang=js&"
export * from "./modal-cookie-main.vue?vue&type=script&lang=js&"
import style0 from "./modal-cookie-main.vue?vue&type=style&index=0&id=a005ff9e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a005ff9e",
  null
  
)

export default component.exports